import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Col, Button, Input, Spinner } from "reactstrap";
import ConfirmPopover from "../ConfirmPopover/ConfirmPopover";
import axios from "axios";
// import {
//   SegmentedControlButtonSM,
// } from "../../Components/SegmentedControlButton/SegmentedControlButton";
import { SegmentedControlButtonSM } from "../SegmentedControlButton/SegmentedControlButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEraser,
  faEnvelope,
  faMobileAlt,
  faMobile,
  faExclamation,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import Select, { components } from "react-select";
import { ToggleButtonGroup, Tooltip, ToggleButton, Alert } from "@mui/material";
import "./MacroEditor.css";
import { toast } from "react-toastify";
import { genLang as genLang2 } from "../../../composables/genLang";
const QL_CURSOR = /\<span class=["']ql-cursor["']>\s*<\/span>/gm;
var toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ color: [] }, { background: [] }],
  ["link"],
];

const MACRO_URL = "https://neobackendstaging-kagyonahja-nn.a.run.app";
const AUTH_ID = localStorage.getItem("user_id");

const getMacrosList = (neogroup) => {
  return axios.get(MACRO_URL + "/getMacrosList", {
    params: {
      auth_id: localStorage.getItem("user_id"),
      neogroup,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin_token"),
    },
  });
};

const editMacro = (obj) => {
  return axios.post(
    MACRO_URL + "/updateMacroPv",
    {
      ...obj,
      auth_id: parseInt(AUTH_ID),
    },
    {
      params: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("admin_token"),
      },
    }
  );
};

const addFileGcloud = (ticket_id, base64, clientUserId) => {
  const obj = {
    AUTH_ID: parseInt(AUTH_ID),
    ticket_id: ticket_id,
    folder: "neodesk",
    bucket: "neo_data_01",
    user_id: parseInt(clientUserId),
    source: "lawyer_portal",
    internal: false,
    base64: base64,
  };
  return axios.post("https://apidoc.neodesk.app/api/v2/desk/uploadFiles", obj, {
    params: {},
    auth: {
      username: "admin",
      password:
        "6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8",
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("admin_token"),
      "x-jwt-token": localStorage.getItem("admin_token"),
    },
  });
};

const putGcloud = (url, obj, type) => {
  return axios.put(url, obj, {
    params: {},
    headers: {
      "Content-Type": type,
    },
  });
};

function MacroEditor({ trad, history }) {
  const [selectedMacro, setSelectedMacro] = useState("");
  const [title, setTitle] = useState("");
  const [assign_id, setAssign_id] = useState("");
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [notifySms, setNotifySms] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [text, setText] = useState("");
  const [lawyerOptions, setLawyerOptions] = useState([]);
  const [macros, setMacros] = useState([]);
  const [invalidSmsMacros, setInvalidSmsMacros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const lang = "fr";

  const genLang = (text) => {
    try {
      return trad["neoleads"][text][lang];
    } catch (e) {
      console.error(text);
      return "";
    }
  };

  const fetchMacros = () => {
    getMacrosList(GROUP)
      .then(({ data }) => {
        const macrosOptions = data.data.macros
          .filter((i) => i.type !== "commonaction")
          .map((i) => {
            return { ...i, label: i.title, value: i.id, text: i.value };
          });
        const invalidMacros = macrosOptions
          .filter((i) => i.notify.notifySms && i.text.length > 1600)
          .map((i) => `${i.value} - ${i.title}`);
        setInvalidSmsMacros(invalidMacros);
        const lawyers = data?.data["lawyers-groups"]?.lawyers.map((i) => {
          return { ...i, label: i.label[lang] };
        });
        setLawyerOptions(lawyers);
        setMacros([
          {
            label: genLang("createNewMacro"),
            value: "ma-new",
            isPublic: false,
          },
          ...macrosOptions,
        ]);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e && e.response && e.response.status) {
          if (e.response?.status === 401) {
            history.push("/dashboard");
          }
        }
        console.log(e);
      });
  };

  const GROUP = "";
  useEffect(() => {
    setLoading(true);
    fetchMacros();
  }, []);

  // const isAdmin = localStorage.getItem("user_type") === "admin" || false;
  // if (!isAdmin) this.props.history.push("/dashboard");

  const handleSubmit = (disable = false) => {
    let str = "";
    if (notifyEmail) str += "notif_email ";
    if (notifySms) str += "notif_sms";
    const obj = {
      id: selectedMacro,
      title,
      actions: {
        current_tags: str,
        comment_mode_is_public: isPublic?.toString(),
        comment_value_html: text,
      },
      neogroup: GROUP,
      ...(disable && { status: 0 }),
      // ...(assign_id && { assign_id }),
    };
    if (notifySms && text.length > 1600) {
      return toast.error(
        genLang2({
          en: "SMS cannot be longer than 1600 characters",
          fr: "Le SMS ne peut pas dépasser 1600 caractères",
        })
      );
    }
    setLoading(true);
    editMacro(obj)
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setLoading(false);
          toast.success(genLang("macroPostSuccess"));
          clearInputs();
          setTimeout(() => {
            fetchMacros();
          }, 2000);
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e && e.response && e.response.status) {
          const { status, statusText } = e.response;
          const str = `Error ${status} - ${statusText}`;
          toast.error(str);
        } else toast.error(genLang("postError"));
      });
  };

  const onChangeFile = async (e) => {
    const files2 = [...e.target.files];
    const files = [...e.target.files].map((j, i) => {
      const name = e.target.files[i].name;
      let reader = new FileReader();
      let obj = {};
      reader.readAsDataURL(j);
      reader.onload = async () => {
        setFileUploadLoading(true);
        let baseURL = reader.result;
        obj.value = "none";
        obj.filename = name;
        const type = baseURL.split(":")[1].split(";")[0];
        obj.datatype = type;
        await addFileGcloud("public", [obj], "0")
          .then(async ({ data }) => {
            const urlList = data.result.map((i) => {
              const str = `<p><br><a href=${i.shortUrlNeodoc} rel="noopener noreferrer" target="_blank">${i.name}</a></p>`;
              setText((text) => text + str);
            });
            await putGcloud(data.result[0].putGcloud, files2[i], type)
              .then((res) => {
                if (res.status === 200) {
                  setFileUploadLoading(false);
                }
              })
              .catch((e) => {
                setFileUploadLoading(false);
                console.log(e?.data);
              });
          })
          .catch((e) => {
            console.log(e);
            setFileUploadLoading(false);
            // const { status, statusText } = e?.response;
            // const str = `Error ${status} - ${statusText}`;
            toast.error("Error");
          });
      };
    });
  };

  const clearInputs = () => {
    setSelectedMacro("");
    setText("");
    setAssign_id("");
    setNotifyEmail(false);
    setNotifySms(false);
    setTitle("");
    setIsPublic(false);
  };

  const handleMacroSelectChange = (e) => {
    if (!e) clearInputs();
    else {
      setSelectedMacro(e.value);
      setText(e.text);
      setAssign_id(e.assign_id);
      setNotifyEmail(e?.notify?.notifyEmail);
      setNotifySms(e?.notify?.notifySms);
      setTitle(e.title);
      setIsPublic(e.isPublic);
    }
  };

  const handleAssignationSelectChange = (e) => {
    if (!e) setAssign_id("");
    else setAssign_id(e.value);
  };

  const handleQuillChange = (note) => {
    if (note === "<p><br></p>") setText("");
    else setText(note);
  };

  const clearQuill = () => {
    setText("");
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "auto",
      marginBottom: "0.1rem",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      zIndex: 3,
    }),
    base: (provided) => ({
      ...provided,
      height: 10,
    }),
  };
  const { Option, SingleValue } = components;
  const IconOption = (props) => (
    <Option {...props}>
      {props.data?.notify?.notifySms && (
        <FontAwesomeIcon
          icon={faMobileAlt}
          style={{ color: "#0076CF", marginRight: "0.5rem" }}
        />
      )}
      {props.data?.notify?.notifyEmail && (
        <FontAwesomeIcon
          icon={faEnvelope}
          style={{ color: "#0076CF", marginRight: "0.5rem" }}
        />
      )}
      {props.data.label}
    </Option>
  );

  const SingleValueOption = (props) => (
    <SingleValue {...props}>
      {props.data?.notify?.notifySms && (
        <FontAwesomeIcon
          icon={faMobileAlt}
          style={{ color: "#0076CF", marginRight: "0.5rem" }}
        />
      )}
      {props.data?.notify?.notifyEmail && (
        <FontAwesomeIcon
          icon={faEnvelope}
          style={{ color: "#0076CF", marginRight: "0.5rem" }}
        />
      )}
      {props.data.label}
    </SingleValue>
  );

  return (
    <Col
      className="jsontable-container"
      style={{ gap: "1rem 1rem", cursor: loading && "wait" }}
    >
       <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5 style={{ color: "#0166b3" }}>{genLang("editMacro")}</h5>
          <span
            style={{
              display: "flex",
              gap: "0.5rem",
              fontWeight: "500",
              alignItems: "center",
              color: "#0076cf",
            }}
          >
            <FontAwesomeIcon icon={faExclamation} />
            {genLang("dontDeleteBrackets")}
          </span>
        </div>
      {invalidSmsMacros?.length > 0 && (
        <Alert severity="error">
          {genLang2({
            en: "The following macros have more than 1600 characters and cannot be sent via SMS",
            fr: "Les macros suivantes ont plus de 1600 caractères et ne peuvent pas être envoyées par SMS",
          })}
          <ul>
            {invalidSmsMacros.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
        </Alert>
      )}
        <div>
          <div className="quill-select-label">Macro</div>
          <Select
            styles={customStyles}
            // isDisabled={notePostLoading}
            isClearable
            isSearchable
            value={macros?.find((i) => i?.value === selectedMacro) || ""}
            menuPlacement={"auto"}
            placeholder={genLang("select")}
            onChange={handleMacroSelectChange}
            options={macros}
            components={{
              Option: IconOption,
              SingleValue: SingleValueOption,
            }}
          />
        </div>
            <div style={{ position: "relative" }}>
        <ReactQuill
          className={`contenteditable ${isPublic && "quill-public"}`}
          readOnly={!selectedMacro}
          style={!selectedMacro ? { cursor: "not-allowed" } : {}}
          name={"notre_dame_des_eclaireurs"}
          theme="snow"
          modules={{ toolbar: toolbarOptions }}
          value={text || ""}
          onChange={(e, a, b, c) => {
            handleQuillChange(e);
          }}
        />
        <div className="quill-icon-buttons">
          <Tooltip title={genLang("clear")}>
            <button className="quill-erase-admin" onClick={clearQuill}>
              <FontAwesomeIcon icon={faEraser} />
            </button>
          </Tooltip>
          {fileUploadLoading ? (
            <Spinner
              size="sm"
              style={{
                position: "absolute",
                right: "68px",
                top: "109px",
                color: "#0076CF",
              }}
              s
            />
          ) : (
            <>
              <Tooltip title={genLang("addFile")}>
                <label
                  style={{ marginBottom: "0px" }}
                  className="macros-file"
                  htmlFor="files"
                >
                  <FontAwesomeIcon icon={faPaperclip} />
                </label>
              </Tooltip>
              <input
                className="files"
                id="files"
                multiple
                type="file"
                hidden
                onChange={(e) => onChangeFile(e)}
              />
            </>
          )}
        </div>
      </div>

      <div className="admin-macros-inputs">
        <div className="admin-macros-inputs">
          <div style={{ minWidth: "300px" }}>
            <div className="quill-select-label">{genLang("title")}</div>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </div>

          <div className="admin-macros-inputs-ispublic">
            <SegmentedControlButtonSM
              options={[
                { label: genLang("privateNote"), value: "private" },
                { label: genLang("publicNote"), value: "public" },
              ]}
              onClick={(e) => {
                if (e.target.value === "private") setIsPublic(false);
                else setIsPublic(true);
              }}
              selected={isPublic ? "public" : "private"}
            />
          </div>
          <div className="admin-macros-notify">
            <ToggleButtonGroup
              value={[
                ...(notifyEmail ? ["notifyEmail"] : []),
                ...(notifySms ? ["notifySms"] : []),
              ]}
              onChange={(e, n) => {
                if (n.includes("notifyEmail")) setNotifyEmail(true);
                else setNotifyEmail(false);
                if (n.includes("notifySms")) setNotifySms(true);
                else setNotifySms(false);
              }}
              style={{ height: "40px" }}
            >
              <ToggleButton value={"notifyEmail"}>
                <span className="admin-macros-notify-btn-text">
                  <FontAwesomeIcon icon={faEnvelope} />
                  Email
                </span>
              </ToggleButton>
              <ToggleButton value="notifySms">
                <span className="admin-macros-notify-btn-text">
                  <FontAwesomeIcon icon={faMobile} />
                  SMS
                </span>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedMacro && (
              <ConfirmPopover
                // tooltip={""}
                style={{ color: "red", fontWeight: "600" }}
                onSubmit={() => handleSubmit(true)}
                message={genLang("confirmDeactivateMacro")}
                buttonText={genLang("confirm")}
                primaryButtonText={genLang("deactivateMacro")}
              />
            )}
          </div>
        </div>
        <div
          style={{ display: "flex", flexGrow: 0.5, justifyContent: "flex-end" }}
        >
          <Button
            color="primary"
            // block
            onClick={() => handleSubmit()}
            className="admin-macros-submit-btn"
            style={{
              cursor: !selectedMacro && "not-allowed",
              width: "80%",
              height: "40px",
            }}
            disabled={!selectedMacro}
          >
            {loading ? <Spinner size="sm" /> : genLang("register")}
          </Button>
        </div>
      </div>
    </Col>
  );
}

export default withRouter(MacroEditor);
