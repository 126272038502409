import React, { Component, useEffect } from "react";
import { APP_PATHNAME } from "./appConfig";
import {
  Router,
  Route,
  NavLink,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
  withRouter,
} from "react-router-dom";
import history from "./history";
import { encryptText, decryptText } from "./appEncryption";

import { BaseHeader } from "./BaseHeader";
import { BaseLeftPanel } from "./BaseLeftPanel";
import { BaseFooter } from "./BaseFooter";

import { BaseHeader2 } from "./BaseHeader2";
import { BaseLeftPanel2 } from "./BaseLeftPanel2";

////////////
//import { Switch, Route } from 'react-router-dom';
//import {connect} from 'react-redux';
import { connect, useSelector } from "react-redux";
import {
  getProfileFetch,
  logoutUser,
  getLoggedUser,
  setLoggedUser,
} from "./redux/actions";
import Signup from "./Signup";
import Login from "./Login";
import VwBoard from "./components/vwfacturas/VwBoard";
import { getDailyGoalSale } from "./actions/NeoSalesPlanningDailyAction";

import { withTranslation } from "react-i18next";

//import ZxCompaniesList from './components/zxcompanies/ZxCompaniesList';
//import ZxCompaniesInfo from './components/zxcompanies/ZxCompaniesInfo';
//import ZxCompaniesForm from './components/zxcompanies/ZxCompaniesForm';

import ZxCompaniesList from "./components/zxcompanies/ZxCompaniesList";
import ZxCompaniesInfo from "./components/zxcompanies/ZxCompaniesInfo";
import ZxCompaniesForm from "./components/zxcompanies/ZxCompaniesForm";

import VwFacturasList from "./components/vwfacturas/VwFacturasList";
import VwFacturasInfo from "./components/vwfacturas/VwFacturasInfo";
import VwFacturasForm from "./components/vwfacturas/VwFacturasForm";

import ZonlineUsuariosList from "./components/zonlineusuarios/ZonlineUsuariosList";
import ZonlineUsuariosInfo from "./components/zonlineusuarios/ZonlineUsuariosInfo";
import ZonlineUsuariosForm from "./components/zonlineusuarios/ZonlineUsuariosForm";

import NeoVentesList from "./components/neoventes/NeoVentesList";
import PendingNeoVentesList from "./components/neoventes/PendingNeoVentesList";
import NeoVentesInfo from "./components/neoventes/NeoVentesInfo";
import NeoVentesForm from "./components/neoventes/NeoVentesForm";
import NeoVentesListx from "./components/neoventes/NeoVentesListx";
import NeoVentesFormInvoice from "./components/neoventes/NeoVentesFormInvoice";
import NeoVentesListupsell from "./components/neoventes/NeoVentesListupsell";
import NeoVentesb2bList from "./components/neoventes/NeoVentesb2bList";
import NeoVentesSearchConfList from "./components/neoventes/NeoVentesSearchConfList";
import NeoVentesBasicPostActions from "./components/neoventes/NeoVentesBasicPostActions";
import NeoVentesSWABasicPostActions from "./components/neoventes/NeoVentesSWABasicPostActions";
//import NeoVentesMySWABasicPostActions from './components/neoventes/NeoVentesMySWABasicPostActions';

import NeoSalesPlanningDailyList from "./components/neosalesplanningdaily/NeoSalesPlanningDailyList";
import NeoSalesPlanningWeeklyList from "./components/neosalesplanningweekly/NeoSalesPlanningWeeklyList";
import NeoSalesPlanningSellerList from "./components/neosalesplanningweekly/NeoSalesPlanningSellerList";
import NeoSalesPlanningWeeklyForm from "./components/neosalesplanningweekly/NeoSalesPlanningWeeklyForm";

import CheckoutEmailEmulator from "./CheckoutEmailEmulator";
import NeoB2bleadProspectsList from "./components/neob2bleadprospects/NeoB2bleadProspectsList";
import NeoB2bleadProspectsListrep from "./components/neob2bleadprospects/NeoB2bleadProspectsListrep";

import ZendLeadsList from "./components/zendleads/ZendLeadsList";
import ZendLeadsInfo from "./components/zendleads/ZendLeadsInfo";
import ZendLeadsForm from "./components/zendleads/ZendLeadsForm";
import ZendMedList from "./components/zendleads/ZendMedList";
import ZendLeadmedsForm from "./components/zendleads/ZendLeadmedsForm";

import SendSMSNoteForm from "./components/neoventes/SendSMSNoteForm";
import NeoProspectsSmsList from "./components/neoprospectssms/NeoProspectsSmsList";
//import NeoProspectsSmsInfo from './components/neoprospectssms/NeoProspectsSmsInfo';
//import NeoProspectsSmsForm from './components/neoprospectssms/NeoProspectsSmsForm';

/*
import trad from './leads/trad';
// import Lead from './leads/leadportal/sales/Lead';
// import Lead from './leads/leadportal/sales/Lead';
import LeadDetails from './leads/leadportal/sales/LeadDetails';

import LeadList from './leads/leadportal/sales/LeadList';
import LeadListAdmin from './leads/leadportal/sales/LeadListAdmin'
import LeadUserAdmin from './leads/leadportal/sales/LeadUserAdmin';
import Lead7 from './leads/leadportal/sales/Lead7';
import ContactDetails from './leads/leadportal/sales/ContactDetails';
 */

import trad from "./srcincorpo/trad/trad";
// import Lead from './srcincorpo/UPDATED_testament/inputComponents/sales/Lead';
// import Lead from './srcincorpo/UPDATED_testament/inputComponents/sales/Lead';
import LeadDetails from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadDetails";

import LeadList from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadList";
import LeadListAdmin from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListAdmin";
import LeadUserAdmin from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadUserAdmin";
import Lead7 from "./srcincorpo/UPDATED_testament/inputComponents/sales/Lead7";
import ContactDetails from "./srcincorpo/UPDATED_testament/inputComponents/sales/ContactDetails";
import LeadDetailsTicket from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadDetailsTicket";
import LeadListclose from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListclose";
import LeadListAdminsuivi from "./srcincorpo/UPDATED_testament/inputComponents/sales/LeadListAdminsuivi";

import ShadowView from "./ShadowView";
import MacroEditor from "./srcincorpo/UPDATED_testament/MacroEditor/MacroEditor";
import MergeTickets from "./srcincorpo/UPDATED_testament/MergeTickets/MergeTickets";
import AdminPrices from "./srcincorpo/UPDATED_testament/AdminPrices/AdminPrices";

class App extends Component {
  componentDidMount = () => {
    //this.props.getProfileFetch();
    //this.props.getDailyGoalSale();
  };
  componentWillUnmount = () => {
    this.props.getProfileFetch();
    //this.props.getDailyGoalSale();
    //console.log("WEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE componentWillUnmount");
  };

  /*handleClick = event => {   this.props.dispatch(
    event.preventDefault()
    // Remove the token from localStorage
    localStorage.removeItem("token")
    // Remove the user object from the Redux store
    this.props.logoutUser()
  }*/

  render() {
    //console.log(".......///..check authentication "+this.props.LoggedUser);
    //console.log(".......///..check STOOOOORE "+this.props.store);
    //const MyAppMain = withTranslation('common')(AppMain);
    //const MyAppMain = withTranslation('common')(AppMain);
    const MyAppMain = AppMain;
    //const { pathname } = useLocation();
    //const { router, params, location, routes, pathname } = this.props;
    //const pathname = this.props.location.pathname; //this.props.location;
    //  const { match, location, history } = this.props;
    //  const mypathname =location.pathname; //this.props.location.path;
    const path = APP_PATHNAME; //document.URL.substr(0,document.URL.lastIndexOf('/'));

    //console.log("WEEEEEEEEEEEEEEEEEEEEE "+mypathname);

    return (
      <Router history={history}>
        <div>
          <Switch>
            {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
            {/* <Route exact path="/xcvbbb" component={Login} />
          <Route path="/signup" component={Signup}/>
          <Route path="/login" component={Login}/>
		  <PrivateRoute path='/protected'  component={MyAppMain} />
		  <PrivateRoute path='/'  component={MyAppMain} />   */}

            <Route exact path={path + "xcvbbb"} component={Login} />
            <Route path={path + "signup"} component={Signup} />
            <Route path={path + "login"} component={Login} />
            <Route
              path={path + "viewemailinvoice"}
              component={CheckoutEmailEmulator}
            />
            <PrivateRoute
              path={path + "protected"}
              {...this.props}
              component={MyAppMain}
            />
            <PrivateRoute path="/" {...this.props} component={MyAppMain} />

            {/* <PrivateRoute path='/protected' render={(routeProps)=> (<MyAppMain {...routeProps} {...this.props} getDailyGoalSale={`getDailyGoalSale`} />)} />
		  <PrivateRoute path='/' render={(props)=> (<MyAppMain {...props} {...this.props} getDailyGoalSale={`getDailyGoalSale`} />)} />  
		   */}
          </Switch>
          {/*this.props.currentUser.username
            ? <button onClick={this.handleClick}>Log Out</button>
            : null*/}

          {/* <div id="contactBand" class="notConnected">
			<div id="contact_username"></div>
			<div id="contact_phone"></div>
			<div id="contact_language"></div>
			<button 
				id="hide"
				type="button"
				title="Cache/ hide"
				class="btn btn-success"
				onClick="document.getElementById('contactBand').classList.add('notConnected');"
			>
				<i class="fas fa-minus"></i>
			</button>
		</div>
		<div id="connected" class="notConnected">
			<div id="softphoneContainer" class="hidden">
				<div id="softphone"></div>
				<div id="softphoneInfo"></div>
			</div>
			<div>
				<button 
					id="togglePhone"
					type="button"
					title="Appels entrants activés/ inbound calls active"
					class="btn btn-success"
					>			
					<i class="fas fa-phone-slash"></i>
					<i class="fas fa-phone"></i>
				</button>
			</div>
		</div>
		<div id="connectLink">
			<a
				target="_blank"
				href="https://neodesk.my.connect.aws"
				type="button"
				title="Appels entrants desactivés/ inbound calls inactive"
				class="btn btn-success"
				>
				<i class="fas fa-wifi"></i>
			</a>
		</div> */}
        </div>
      </Router>
    );
  }
}

const PrivateRoute2 = ({ component: Component, ...rest }) => {
  ////console.log("check authentication "+getLoggedUser());  LoggedUser

  //const user = useSelector(state => state.LoggedUser.currentUser);
  let isLoggedIn = false;
  let isAuthenticated = false;
  //useEffect to avoid the Error: Minified React error #185;
  //useEffect(() => {
  //setTimeout(() => {

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: APP_PATHNAME + "login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  ////console.log("check authentication "+getLoggedUser());  LoggedUser
  /*//console.log("loggedUser check   getProtectedThing "+ eval(getProtectedThing()) ); 
  //console.log("loggedUser check   yooooooooooooo "+ getLoggedUser );  getProtectedThing()
  //console.log("..check authentication "+getLoggedUser());*/
  //const state = getState();
  //const user = state.currentUser;
  //console.log("..PRIVATEROUTE check authentication############################# ");
  //const isLoggedIn = useSelector((state: IApplicationState) => state.auth.isLoggedIn);
  //props.currentUser.isAuthenticated === true

  const user = useSelector((state) => state.LoggedUser.currentUser);
  let isLoggedIn = false;
  let isAuthenticated = false;
  //useEffect to avoid the Error: Minified React error #185;
  //useEffect(() => {
  //setTimeout(() => {

  //   let isLoggedIn =false;
  //   let isAuthenticated=false;
  const tokenExist = localStorage.token;
  const rawUserExist = localStorage.user;

  //const user = useSelector(state => state.LoggedUser.currentUser);
  //console.log("..check PrivateRoute "+rawUserExist+"  ===  "+tokenExist+"  ===  "+user);
  //if(user==undefined && user!==null){
  //if((tokenExist==undefined && tokenExist!==null) && (rawUserExist==undefined && rawUserExist!==null)){  //if(user==undefined || user!==null){
  //const user = useSelector(state => state.LoggedUser.currentUser);
  //const state = store.getState();
  //const user = state.currentUser;
  isLoggedIn = user.isAuthenticated;
  const currentUserx = user.currentUser; //user.currentUser;
  let userId = user.id;
  const token = decryptText(localStorage.token);
  const rawUser = decryptText(localStorage.user);

  if (
    isLoggedIn === undefined &&
    userId === undefined &&
    token !== undefined &&
    token !== null
  ) {
    const userStr = rawUser.split(":");
    const rawUserx = {
      id: userStr[1],
      name: userStr[2],
      role: userStr[3],
      isAuthenticated: true,
    }; //rawUser; //JSON.parse(rawUser);
    let neoState = {
      currentUser: rawUserx,
      id: userStr[1],
      name: userStr[2],
      role: userStr[3],
      isAuthenticated: true,
    };
    //console.log("RAAAAAAAAAAAAAW DATA "+rawUserx.isAuthenticated+" "+rawUserx.id+" "+rawUser);
    if (rawUserx.id > 0) {
      isLoggedIn = true;
      userId = rawUserx.id;
      setLoggedUser(neoState);
    }
  }
  // let isAuthenticated=false;
  //console.log("..====== user  ");
  //console.log(user);
  //console.log("..====== raw user  ");
  //console.log(rawUser);
  //console.log("..end====== user is? "+isLoggedIn+" id= "+userId);
  if (
    isLoggedIn === true &&
    userId > 0 &&
    token !== undefined &&
    token !== null
  ) {
    isAuthenticated = true;
  }
  //console.log("..====== IS user isAuthenticated? "+isAuthenticated);
  //}

  console.log("..====== PROOOOPS  ", rest);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: APP_PATHNAME + "login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  //}, []); //useEffect

  //return null;
  //}, 100);

  /*<Route {...rest} render={(props) => (
    getLoggedUser.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/Login',
          state: { from: props.location }
        }} />
  )} />*/
};

function transformJSONStr(str) {
  const x = str.split(",");
  let xq = {};
  for (let k = 0; k < x.length; k++) {
    const kv = x[k].split(":");
    xq[kv[0]] = kv[1];
  }

  return xq;
}

function getProtectedThing() {
  return (dispatch, getState) => {
    // grab current state
    const state = getState();

    // get the JWT token out of it
    // (obviously depends on how your store is structured)
    const user = state.currentUser;

    return user;
  };
}

class AppMain extends Component {
  _isMounted = false; //true;
  script = null;

  constructor(props) {
    super(props);
    //const { t } = this.props;
    //this.isLeftMenuOpen = true;
    this.state = { isLeftMenuOpen: false, isLeftMenuOpen2: false };
    //let {path, url} = useRouteMatch();
    //{`${path}/zxteams`}
    let path = this.props.location; //this.props.path;
    //console.log("AppMain WEEEEEEEEEEEEEEEEEEEEE ");//console.log(path);//console.log("//AppMain WEEEEEEEEEEEEEEEEEEEEE ");

    //this.handleLeftPanelMenu = this.handleLeftPanelMenu.bind(this);
    ////this.handleSubmit = this.handleSubmit.bind(this);
    ////this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  handleLeftPanelMenu(event) {
    event.preventDefault();
    //this.setState(this.initialState);
    let valMenuToggle = true;
    if (this.state.isLeftMenuOpen) {
      valMenuToggle = false;
    } else {
      valMenuToggle = true;
    }
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen: valMenuToggle,
      });

      //console.log("TEEEEEEEEEEEEEEEEEEEEST TOGGLE ");
      //console.log(" LEFTMENU OPEN "+this.state.isLeftMenuOpen);
    }
  }

  handleLeftPanelMenu2(event) {
    //event.preventDefault();
    let valMenuToggle = true;
    if (this.state.isLeftMenuOpen2) {
      valMenuToggle = false;
    } else {
      valMenuToggle = true;
    }
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen2: valMenuToggle,
      });

      //console.log("+++temp TEEEEEEEEEEEEEEEEEEEEST TOGGLE "+valMenuToggle);
      //console.log("+++temp LEFTMENU OPEN "+this.state.isLeftMenuOpen2);
    }
  }

  handleLeftPanelMenu2x(event) {
    //event.preventDefault();
    let valMenuToggle = false;
    /*if(this.state.isLeftMenuOpen2){
      valMenuToggle=false;
    } else {
      valMenuToggle=true;
    }*/
    if (this._isMounted) {
      this.setState({
        isLeftMenuOpen2: valMenuToggle,
      });

      //console.log("+++CLOSEtemp TEEEEEEEEEEEEEEEEEEEEST TOGGLE "+valMenuToggle);
      //console.log("+++CLOSEtemp LEFTMENU OPEN "+this.state.isLeftMenuOpen2);
    }
  }

  render() {
    const { t } = this.props;
    //console.log(" LEFTMENU GOOOOOOOOOO OPEN "+this.state.isLeftMenuOpen2);
    //console.log("AppMain pathname "+this.props.location.pathname+" vs APP_PATHNAME "+APP_PATHNAME);
    //console.log("Main render PROOOOOOOOOOOOOOOOOOPS", this.props, "mathparams=");
    return (
      <div
        className={
          this.state.isLeftMenuOpen === true
            ? "hold-transition sidebar-mini"
            : "sidebar-mini sidebar-collapse"
        }
        id="topHomeFirst"
      >
        <div className="wrapper">
          {/*<BaseHeader handleLeftPanelMenuRef={ this.handleLeftPanelMenu.bind(this) }  />*/}
          <BaseHeader2
            handleLeftPanelMenuRef={this.handleLeftPanelMenu2.bind(this)}
          />
          {/*<BaseLeftPanel />*/}
          <BaseLeftPanel2
            openLeftMenu={this.state.isLeftMenuOpen2}
            handleLeftPanelMenuRefx={this.handleLeftPanelMenu2x.bind(this)}
          />

          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  {/*<div className="col-sm-6">
						<h1 className="m-0 text-dark">Starter Page</h1>
					  </div> 
					  <div className="col-sm-6">
						<ol className="breadcrumb float-sm-right">
						  <li className="breadcrumb-item"><a href="#">Home</a></li>
						  <li className="breadcrumb-item active">Starter Page</li>
						</ol>
					  </div>*/}
                </div>
              </div>
            </div>

            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  {this.props.location.pathname === "/" ||
                  this.props.location.pathname === APP_PATHNAME ? (
                    <>
                      <VwBoard />
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              &nbsp; <br /> <h3>WELCOME ON THE DASH BOARD</h3>{" "}
                              <br /> &nbsp; <br />
                            </div>
                            <Main />
                            <div>
                              &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                            </div>
                            <div>
                              &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                            </div>
                            <div>
                              &nbsp; <br /> &nbsp; <br />
                              {/* {t('welcome')} */}
                              &nbsp; <br />
                            </div>
                            <div>
                              &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                            </div>
                            <div>
                              &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                            </div>
                            <div>
                              &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                            </div>
                          </div>
                        </div>

                        {/* <div className="card card-primary card-outline">
						  <div className="card-header">
							<h5 className="m-0">Featured qx</h5>
						  </div>
						  <div className="card-body">
							<h6 className="card-title">Special title treatment</h6>

							<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
							<a href="#" className="btn btn-primary">Go somewhere</a>
						  </div>
						</div> */}
                      </div>

                      {/* <div className="col-lg-12"> 
						<div className="card">
						  <div className="card-header">
							<h5 className="m-0">Featured w2</h5>
						  </div>
						  <div className="card-body">
							<h6 className="card-title">Special title treatment</h6>

							<p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
							<a href="#" className="btn btn-primary">Go somewhere</a>
						  </div>
						</div> 
					  </div>  */}
                    </>
                  ) : (
                    <div className="col-lg-12">
                      <div className="card">
                        {/*<div className="card-header">
							<h5 className="m-0">Featured w2777</h5>
						  </div> */}
                        <div className="card-body">
                          {/*
							<Main /> <NeoVentesForm />
						   */}
                          <Main {...this.props} />

                          {/* <NeoVentesForm /> */}
                          <div>
                            &nbsp; <br /> &nbsp; <br /> &nbsp; <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <BaseFooter />
        </div>
      </div>
    );
  }
}

const Navigation = () => (
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <NavLink
          exact
          className="nav-link"
          activeClassName="active"
          to="./zxteams"
        >
          Articles
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          exact
          className="nav-link"
          activeClassName="active"
          to="./zxteams/new"
        >
          Add Article
        </NavLink>
      </li>
    </ul>
  </nav>
);

/*<Route exact path="/" component={Login} />
          <Route path="/signup" component={Signup}/>
          <Route path="/login" component={Login}/>
		  <PrivateRoute path='/protected' component={AppMain} />*/

//<PrivateRoute exact path='/protected' component={AppMain} />

const Main = (props) => {
  let { path, url } = useRouteMatch();
  const mathparams = useRouteMatch();
  //const mypathname =document.URL.substr(0,document.URL.lastIndexOf('/'));
  //{`${path}/zxteams`}
  // path=path+"/";
  // path=path.replace("//", "/");
  const pathprev = path;
  path = APP_PATHNAME;
  // console.log("Main Switch Route WEEEEEEEEEEEEEEEEEEEEE "+path+' ---- '+pathprev);
  //console.log("Main Switch PROOOOOOOOOOOOOOOOOOPS", props, "mathparams=", mathparams);
  return (
    <Switch>
      {/* <Route exact path="/zxcompanies" component={ZxCompaniesList} />
		<Route exact path="/zxcompanies/new" component={ZxCompaniesForm} />
		<Route exact path="/zxcompanies/:id" component={ZxCompaniesInfo} />
		<Route exact path="/zxcompanies/:id/edit" component={ZxCompaniesForm} />
		
		<Route exact path="/zonlineusuarios" component={ZonlineUsuariosList} />
		<Route exact path="/zonlineusuarios/new" component={ZonlineUsuariosForm} />
		<Route exact path="/zonlineusuarios/:id" component={ZonlineUsuariosInfo} />
		<Route exact path="/zonlineusuarios/:id/edit" component={ZonlineUsuariosForm} />
		
		<Route exact path="/vwfacturas" component={VwFacturasList} />
		<Route exact path="/vwfacturas/new" component={VwFacturasForm} />
		<Route exact path="/vwfacturas/:id" component={VwFacturasInfo} />
		<Route exact path="/vwfacturas/:id/edit" component={VwFacturasForm} />

		<Route exact path="/neoventes" component={NeoVentesForm} />
		<Route exact path="/neoventes/:id/invoice" component={NeoVentesForm} />
		<Route exact path="/vwneoventes" component={NeoVentesList} />
		<Route exact path="/vwneoventespending" component={PendingNeoVentesList} />
		<Route exact path="/neoventes/new" component={NeoVentesForm} />
		<Route exact path="/neoventes/:id" component={NeoVentesInfo} />
		<Route exact path="/neoventes/:id/edit" component={NeoVentesForm} />

		<Route exact path="/salesplanningdaily" component={NeoSalesPlanningDailyList} />
		<Route exact path="/salesplanningweekly" component={NeoSalesPlanningWeeklyList} /> */}

      <Route exact path={path + "zxcompanies"} component={ZxCompaniesList} />
      <Route
        exact
        path={path + "zxcompanies/new"}
        component={ZxCompaniesForm}
      />
      <Route
        exact
        path={path + "zxcompanies/:id"}
        component={ZxCompaniesInfo}
      />
      <Route
        exact
        path={path + "zxcompanies/:id/edit"}
        component={ZxCompaniesForm}
      />

      <Route
        exact
        path={path + "zonlineusuarios"}
        component={ZonlineUsuariosList}
      />
      <Route
        exact
        path={path + "zonlineusuarios/new"}
        component={ZonlineUsuariosForm}
      />
      <Route
        exact
        path={path + "zonlineusuarios/:id"}
        component={ZonlineUsuariosInfo}
      />
      <Route
        exact
        path={path + "zonlineusuarios/:id/edit"}
        component={ZonlineUsuariosForm}
      />

      <Route exact path={path + "vwfacturas"} component={VwFacturasList} />
      <Route exact path={path + "vwfacturas/new"} component={VwFacturasForm} />
      <Route exact path={path + "vwfacturas/:id"} component={VwFacturasInfo} />
      <Route
        exact
        path={path + "vwfacturas/:id/edit"}
        component={VwFacturasForm}
      />

      <Route exact path={path + "neoventes"} component={NeoVentesForm} />
      <Route
        exact
        path={path + "neoventes/:id/invoice"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:id/:ticket/onSaleClose"}
        component={NeoVentesForm}
      />
      {/* <Route exact path={path+"neoventes/:id/invoice"} component={NeoVentesFormInvoice} /> */}

      <Route exact path={path + "vwneoventes"} component={NeoVentesList} />
      <Route
        exact
        path={path + "vwneoventespending"}
        component={PendingNeoVentesList}
      />
      <Route exact path={path + "neoventes/new"} component={NeoVentesForm} />
      <Route exact path={path + "neoventes/:id"} component={NeoVentesInfo} />
      <Route
        exact
        path={path + "neoventes/:id/edit"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:idl/leadId"}
        component={NeoVentesForm}
      />
      <Route
        exact
        path={path + "neoventes/:idc/:idl/:idt/fromlead"}
        component={NeoVentesForm}
      />
      <Route exact path={path + "allstatussales"} component={NeoVentesListx} />
      <Route
        exact
        path={path + "vwneoventesupsell"}
        component={NeoVentesListupsell}
      />
      <Route
        exact
        path={path + "vwneoventesb2b"}
        component={NeoVentesb2bList}
      />
      <Route exact path={path + "sendsmscallfrm"} component={SendSMSNoteForm} />

      <Route
        exact
        path={path + "salesplanningdaily"}
        component={NeoSalesPlanningDailyList}
      />
      <Route
        exact
        path={path + "salesplanningweekly"}
        component={NeoSalesPlanningWeeklyList}
      />

      <Route
        exact
        path={path + "salesplanningpeople"}
        component={NeoSalesPlanningSellerList}
      />
      <Route
        exact
        path={path + "salesplanningweekly/new"}
        component={NeoSalesPlanningWeeklyForm}
      />
      <Route
        exact
        path={path + "salesplanningweekly/:id/edit"}
        component={NeoSalesPlanningWeeklyForm}
      />

      <Route
        exact
        path={path + "prospectlist"}
        component={NeoB2bleadProspectsList}
      />
      <Route
        exact
        path={path + "prospectreport"}
        component={NeoB2bleadProspectsListrep}
      />

      <Route
        exact
        path={path + "searchconflictlist"}
        component={NeoVentesSearchConfList}
      />
      <Route
        exact
        path={path + "basicPostSellactions"}
        component={NeoVentesBasicPostActions}
      />
      <Route
        exact
        path={path + "basicSWAPostSellactions"}
        component={NeoVentesSWABasicPostActions}
      />
      {/* <Route exact path={path+"basicMySWAPostSellactions"} component={NeoVentesMySWABasicPostActions} /> */}

      <Route exact path={path + "zendleads"} component={ZendLeadsList} />
      <Route exact path={path + "myzendleads"} component={ZendLeadsList} />
      <Route exact path={path + "zendleads/new"} component={ZendLeadsForm} />
      <Route exact path={path + "zendleads/:id"} component={ZendLeadsInfo} />
      <Route
        exact
        path={path + "zendleads/:id/edit"}
        component={ZendLeadsForm}
      />
      <Route exact path={path + "zendmeds"} component={ZendMedList} />
      <Route
        exact
        path={path + "zendmeds/:id/edit"}
        component={ZendLeadmedsForm}
      />
      <Route
        exact
        path={path + "prospectssmslist"}
        component={NeoProspectsSmsList}
      />

      {/* <PrivateRoute
                  exact
                  logged={this.state.logged}
                  path={["/leads","/leads"]}
                  render={(route) => {    
                    return  (
                      <>
                        <SideBar/>
                        <LeadList trad={trad}/>
                      </>
                    )
                  }}
                />

                <AdminRoute 
                exact
                path={'/leads/admin'}
                render={(route) => {    
                  return  (
                    <>
                      <SideBar/>
                      <LeadListAdmin trad={trad} />
                    </>
                  )
                }}
                />

                <AdminRoute 
                exact
                path={'/users/admin'}
                render={(route) => {    
                  return  (
                    <>
                      <SideBar/>
                      <LeadUserAdmin trad={trad}/>
                    </>
                  )
                }}
                />

                
                <PrivateRoute
                  exact
                  logged={this.state.logged}
                  path={["/leads/:id"]}
                  key={Date.now()}
                  render={(route) => {    
                    // debugger;
                    return  (
                      <>
                        <SideBar/>
                        <LeadDetails 
                          id={route.match.params.id}
                          trad={trad}
                        />
                      </>
                    )
                  }}
                />

                <PrivateRoute
                  exact
                  logged={this.state.logged}
                  path={["/contact"]}
                  render={(route) => {    
                    return  (
                      <>
                        <SideBar/>
                        <ContactDetails trad={trad}/>
                      </>
                    )
                  }}
                /> */}

      {/* <Route exact path={path+"leads"} component={LeadList} trad={trad} />
		<Route exact path={path+"leads/admin"} component={LeadListAdmin} trad={trad} />
		<Route exact path={path+"users/admin"} component={LeadUserAdmin} trad={trad} />
		<Route exact path={path+"leads/:id"} component={LeadDetails} trad={trad} />
		<Route exact path={path+"contact"} component={ContactDetails} trad={trad} /> */}

      {/* 
		<Route exact path={path+"leads"} render={(route) => {    
                    return  (<LeadList trad={trad}/>) }}  /> 
		<Route exact path={path+"leads/admin"} render={(route) => {    
                    return  (<LeadListAdmin trad={trad}/>) }}  /> 
		<Route exact path={path+"users/admin"} render={(route) => {    
                    return  (<LeadUserAdmin trad={trad}/>) }}  /> 
		<Route exact path={path+"leads/:id"} render={(route) => {    
                    return  (<LeadDetails {...props} trad={trad}/>) }}  /> 
		<Route exact path={path+"contact"} render={(route) => {    
                    return  (<ContactDetails trad={trad}/>) }}  />  */}

      {/* //return  (<ShadowView><LeadList trad={trad}/></ShadowView>) }}  />  */}

      <Route
        exact
        path={path + "leads"}
        render={(route) => {
          return <LeadList trad={trad} history={history} />;
        }}
      />
      <Route
        exact
        path={path + "leadsclose"}
        render={(route) => {
          return <LeadListclose trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/admin"}
        render={(route) => {
          return <LeadListAdmin trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/adminsuivi"}
        render={(route) => {
          return <LeadListAdminsuivi trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "users/admin"}
        render={(route) => {
          return <LeadUserAdmin trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "leads/:id"}
        render={(route) => {
          return (
            <LeadDetails {...props} id={route.match.params.id} trad={trad} />
          );
        }}
      />
      <Route
        exact
        path={path + "leads/:id/:ta/:eventId"}
        render={(route) => {
          return (
            <LeadDetails
              {...props}
              id={route.match.params.id}
              ta={route.match.params.ta}
              eventId={route.match.params.eventId}
              trad={trad}
            />
          );
        }}
      />
      <Route
        exact
        path={path + "agent/tickets/:id"}
        render={(route) => {
          return (
            <LeadDetailsTicket
              {...props}
              trad={trad}
              id={route.match.params.id}
            />
          );
        }}
      />
      <Route
        exact
        path={path + "contact"}
        render={(route) => {
          return <ContactDetails trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "macros"}
        render={(route) => {
          return <MacroEditor trad={trad} />;
        }}
      />
      <Route
        exact
        path={path + "merge-tickets"}
        render={(route) => {
          return <MergeTickets trad={trad} />;
        }}
      />
	  <Route
        exact
        path={path + "prices"}
        render={(route) => {
          return <AdminPrices trad={trad} />;
        }}
      />
      {/* <ShadowView>	</ShadowView> */}
    </Switch>
  );
};

//export default App;
const mapStateToProps = (state) => ({
  currentUser: state.LoggedUser.currentUser,
  testFXz: "wewelebret",
});

//currentUser: state.reducer.currentUser

const mapDispatchToProps = (dispatch) => ({
  getProfileFetch: () => dispatch(getProfileFetch()),
  logoutUser: () => dispatch(logoutUser()),
  getDailyGoalSale: () => dispatch(getDailyGoalSale()),
  loggedUser: getLoggedUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));

//const mapStateToProps = (state) => ({ ZxTeams: state.ZxTeams });
//const mapDispatchToProps = { addOrUpdateZxTeams, getZxTeams, getZxTeamsAll, getZxTeamsGoto, deleteZxTeams };
//export default connect(mapStateToProps, mapDispatchToProps)(ZxTeamsList);
