import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faEdit,
  faFlag,
  faPlus,
  faRedo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { Checkbox, Collapse, Tooltip } from "@mui/material";
import "./AdminPrices.css";
import Toast from "../Toast/Toast";
import Table from "../utils/MaterialTable";
import IconButton from "../IconButton/IconButton";
import { FormControlLabel } from "@mui/material";
import { DeactivateProductModal } from "./DeactivateProductModal";
import { genLang } from "../../../../src/composables/genLang";
import { Chip } from "@material-ui/core";
import SegmentedControlButton from "../SegmentedControlButton/SegmentedControlButton";
import { ReactivateProductModal } from "./ReactivateProductModal";
const URL = process.env.REACT_APP_API_URLX;

export default function AdminPrices() {
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({ type: "", message: "" });
  const [tab, setTab] = useState("active");
  const [products, setProducts] = useState([]);
  const [inactiveProducts, setInactiveProducts] = useState([]);
  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const [showDeactiveProductModal, setShowDeactivateProductModal] =
    useState(false);
  const [selectedReactivateProduct, setSelectedReactivateProduct] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState({
    options: [],
    linked_products: [],
  });
  const [domains, setDomains] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [groupDomains, setGroupDomains] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    axios
      .get(URL + "/productsInfosList", {
        params: {
          auth_id: localStorage.getItem("user_id"),
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("admin_token"),
        },
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          const { domains, groups } = res.data.data;
          const groupsData = groups.map((i) => {
            const children = !i?.parent
              ? groups
                  .filter((x) => x?.parent === i?.id)
                  .map((i) => ({ ...i, label: genLang(i?.name), value: i?.id }))
              : [];
            return {
              ...i,
              label: genLang(i.name),
              value: i.id,
              subgroups: children,
            };
          });
          const groupDomains = groupsData.filter((i) => i.domain);
          const activeProds = res.data.data.products.filter(
            (i) => i.published === true
          );
          const inactiveProds = res.data.data.products.filter(
            (i) => i.published !== true
          );
          const products = activeProds.map((i) => {
            const optionsArr = Object.values(i?.options);
            const optionsStr = optionsArr
              .map((i) => `${i?.label["fr"]} ($${i?.value})`)
              .join("\n");
            const productGroupsWithLabels = groupsData.filter((x) =>
              i?.groups.includes(x.id)
            );
            return {
              ...i,
              actions: (
                <div style={{ display: "flex", gap: "1rem" }}>
                  <IconButton
                    onClick={() => openDeactivateProductModal(i)}
                    icon={faTrash}
                    style={{ color: "#e15454" }}
                    tooltip={"Désactiver le produit"}
                  />
                  <IconButton
                    onClick={() => openModal(i)}
                    icon={faEdit}
                    tooltip={"Modifier le produit"}
                    style={{ color: "#0076cf" }}
                  />
                </div>
              ),
              tableTitleFR: i.title["fr"],
              tableTitleEN: i.title["en"],
              isUpsell: i?.upsell ? "Upsell" : "",
              groupsTable: i?.groups?.length ? (
                <>
                  {productGroupsWithLabels.map((i) => (
                    <Chip
                      size="small"
                      label={genLang(i?.name)}
                      style={{ marginRight: "2px", maxWidth: "125px" }}
                    />
                  ))}
                </>
              ) : (
                <Tooltip
                  title={genLang({ en: "No group(s)", fr: "Aucun groupe(s)" })}
                  placement="left"
                >
                  <div>
                    <FontAwesomeIcon icon={faFlag} style={{ color: "red" }} />
                  </div>
                </Tooltip>
              ),
              optionsNum: (
                <Tooltip
                  title={
                    optionsArr?.length ? (
                      <div style={{ whiteSpace: "pre-line", fontSize: "13px" }}>
                        {optionsStr}
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  <span>{optionsArr.length}</span>
                </Tooltip>
              ),
            };
          });
          const inactiveProducts = inactiveProds.map((i) => {
            return {
              ...i,
              tableTitleFR: i.title["fr"],
              tableTitleEN: i.title["en"],
              actions: (
                <div style={{ display: "flex", gap: "1rem" }}>
                  <IconButton
                    onClick={() => setSelectedReactivateProduct(i)}
                    icon={faRedo}
                    style={{ color: "#0076cf" }}
                    tooltip={"Réactiver le produit"}
                  />
                </div>
              ),
            };
          });
          setGroupDomains(groupDomains);
          setGroupOptions(groupsData);
          setProducts(products);
          setDomains(domains);
          setInactiveProducts(inactiveProducts);
        }
      })
      .catch((e) => {
        console.error(e);
        setToast({
          type: "error",
          message: "Erreur lors de la récupération des produits.",
        });
      });
  };

  const openModal = (product) => {
    setShowEditPriceModal(true);
    setSelectedProduct(product);
  };

  const openDeactivateProductModal = (product) => {
    setShowDeactivateProductModal(true);
    setSelectedProduct(product);
  };

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Titre FR", accessor: "tableTitleFR" },
    { Header: "Titre EN", accessor: "tableTitleEN" },
    { Header: "Upsell", accessor: "isUpsell" },
    { Header: "Province", accessor: "province" },
    { Header: "Options", accessor: "optionsNum" },
    {
      Header: genLang({ en: "Groups", fr: "Groupes" }),
      accessor: "groupsTable",
    },
    { Header: "Prix", accessor: "amount" },
    { Header: "Actions", accessor: "actions" },
  ];

  const inactiveColumns = [
    { Header: "ID", accessor: "id" },
    { Header: "Titre FR", accessor: "tableTitleFR" },
    { Header: "Titre EN", accessor: "tableTitleEN" },
    { Header: "Prix", accessor: "amount" },
    { Header: "Actions", accessor: "actions" },
  ];

  const changeSelectedProductTitle = (e, lang = "fr") => {
    const newProduct = {
      ...selectedProduct,
      title: { ...selectedProduct.title, [lang]: e.target.value },
    };
    setSelectedProduct(newProduct);
  };

  const changeSelectedProductPrice = (e) => {
    const newProduct = {
      ...selectedProduct,
      amount: e.target.value,
    };
    setSelectedProduct(newProduct);
  };

  const changeSelectedProductFee = (e) => {
    const newProduct = {
      ...selectedProduct,
      discountFree: Number(e.target.value),
    };
    setSelectedProduct(newProduct);
  };

  const changeSelectedProductGroups = (e) => {
    const newProduct = {
      ...selectedProduct,
      groups: e ? e.map((i) => i.id) : [],
    };
    setSelectedProduct(newProduct);
  };

  const handleLinkedProductsChange = (products) => {
    const newProduct = {
      ...selectedProduct,
      linked_products: products.map((i) => i.value),
    };
    setSelectedProduct(newProduct);
  };

  const handleChangeGroups = (groups) => {
    const newProduct = {
      ...selectedProduct,
      groups: groups.map((i) => i.value),
    };
    setSelectedProduct(newProduct);
  };

  const handleCreateProductSubmit = (infos) => {
    setLoading(true);
    axios
      .post(
        URL + "/addProduct",
        {
          auth_id: localStorage.getItem("user_id"),
          infos,
        },
        {
          params: {},
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("admin_token"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setShowCreateProductModal(false);
          setLoading(false);
          setToast({
            type: "success",
            message: "Produit créé avec succès!",
          });
          fetchProducts();
          return true;
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setToast({
          type: "error",
          message: "Produit créé sans succès, veuillez réessayer.",
        });
        return false;
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    const infos = { ...selectedProduct };
    delete infos?.id;
    delete infos?.cat_text;
    infos.neoprod_breakdown.fideicommis =
      infos?.neoprod_breakdown?.invoice?.find((i) => i?.key === "2")
        ?.percentage ?? "";
    axios
      .post(
        URL + "/editProductInfos",
        {
          auth_id: localStorage.getItem("user_id"),
          id: selectedProduct?.id,
          infos,
        },
        {
          params: {},
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("admin_token"),
          },
        }
      )
      .then((res) => {
        if (res.data.statusCode === 200) {
          setShowEditPriceModal(false);
          setLoading(false);
          setToast({
            type: "success",
            message: "Produit modifié avec succès!",
          });
          fetchProducts();
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setToast({
          type: "error",
          message: "Produit modifié sans succès, veuillez réessayer.",
        });
      });
  };

  const handleInvoiceChange = (e, key) => {
    const invoice = selectedProduct.neoprod_breakdown.invoice.find(
      (i) => i.key === key
    );
    const newInvoice = { ...invoice, [e.target.name]: e.target.value };
    const newInvoices = selectedProduct.neoprod_breakdown.invoice.map((i) => {
      if (i.key === key) return newInvoice;
      return { ...i };
    });
    setSelectedProduct({
      ...selectedProduct,
      neoprod_breakdown: {
        ...selectedProduct.neoprod_breakdown,
        invoice: newInvoices,
      },
    });
  };

  const productOptions =
    products.map((i) => ({
      label: genLang(i?.title ?? ""),
      value: i.id,
    })) ?? [];

  return (
    <Col className="jsontable-container">
      <Toast
        type={toast.type}
        message={toast.message}
        clear={() => setToast({ type: "", message: "" })}
        autoHide
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <h3 style={{ color: "#0076cf" }}>Produits</h3>
        <div>
          <SegmentedControlButton
            options={[
              { label: "Actifs", value: "active" },
              { label: "Inactifs", value: "inactive" },
            ]}
            onClick={(e) => setTab(e.target.value)}
            selected={tab}
          />
        </div>
      </div>
      <Table
        columns={tab === "active" ? columns : inactiveColumns}
        data={tab === "active" ? products : inactiveProducts}
        searchBarComponent={
          <div style={{ display: "flex", gap: "1rem", height: "40px" }}>
            <DownloadButtonCSV data={products} fileName={"products.csv"} />
            <Button
              size="sm"
              color="primary"
              outline
              style={{ fontWeight: "600", borderRadius: "50px" }}
              onClick={() => setShowCreateProductModal(true)}
            >
              <FontAwesomeIcon icon={faPlus} /> Ajouter un produit
            </Button>
          </div>
        }
      />
      <EditProductModal
        setSelectedProduct={setSelectedProduct}
        close={() => setShowEditPriceModal(false)}
        handleInvoiceChange={handleInvoiceChange}
        isOpen={showEditPriceModal}
        selectedProduct={selectedProduct}
        changeSelectedProductTitle={changeSelectedProductTitle}
        changeSelectedProductPrice={changeSelectedProductPrice}
        changeSelectedProductFee={changeSelectedProductFee}
        changeSelectedProductGroups={changeSelectedProductGroups}
        handleChangeGroups={handleChangeGroups}
        handleSubmit={handleSubmit}
        loading={loading}
        groupOptions={groupOptions}
        groupDomains={groupDomains}
        productOptions={productOptions}
        handleLinkedProductsChange={handleLinkedProductsChange}
      />
      <CreateProductModal
        close={() => setShowCreateProductModal(false)}
        isOpen={showCreateProductModal}
        domains={domains}
        handleCreateProductSubmit={handleCreateProductSubmit}
        groupOptions={groupOptions}
        groupDomains={groupDomains}
        productOptions={productOptions}
      />
      <DeactivateProductModal
        setToast={setToast}
        setProducts={setProducts}
        fetchData={fetchProducts}
        products={products}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        isOpen={showDeactiveProductModal}
        toggle={() => setShowDeactivateProductModal(!showDeactiveProductModal)}
      />
      <ReactivateProductModal
        setToast={setToast}
        fetchData={fetchProducts}
        setInactiveProducts={setInactiveProducts}
        inactiveProducts={inactiveProducts}
        selectedProduct={selectedReactivateProduct}
        isOpen={selectedReactivateProduct !== null}
        toggle={() => setSelectedReactivateProduct(null)}
      />
    </Col>
  );
}

function CreateProductModal({
  isOpen,
  close,
  loading,
  domains,
  handleCreateProductSubmit,
  groupOptions,
  groupDomains,
  productOptions,
}) {
  const defaultData = {
    domain: "",
    domain_name: "",
    sub_domain: "",
    province: "",
    class: 0,
    title: {
      en: "",
      fr: "",
    },
    type: [],
    amount: "",
    apartir: false,
    cat: 0,
    cat_reporting: "",
    base_product: 0,
    upsell: 0,
    options: [],
    linked_products: [],
    groups: [],
    neoprod_breakdown: {
      invoice: [
        {
          key: "3",
          fr: "Service juridique",
          en: "Legal service",
          percentage: 25,
        },
        {
          key: "4",
          fr: "Gestion du dossier",
          en: "Case Management",
          percentage: 25,
        },
        {
          key: "5",
          fr: "Utilisation technologique",
          en: "Technological use",
          percentage: 50,
        },
        {
          key: "2",
          fr: "Fidéicommis",
          en: "Fideicommis",
          percentage: 0,
        },
      ],
      invoice_options: [],
      fideicommis: 0,
    },
  };
  const [data, setData] = useState(defaultData);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedSubgroups, setSelectedSubgroups] = useState([]);

  const types = [
    "subgroup1",
    "subgroup3",
    "group2",
    "group3",
    "group6",
    "section1_1",
  ].map((i) => {
    return { label: i, value: i };
  });

  const provinces = [
    { label: "QC", value: "QC" },
    { label: "ON", value: "ON" },
  ];

  const handleLabelChange = (e, lang = "fr") => {
    setData({ ...data, title: { ...data.title, [lang]: e.target.value } });
  };

  const handleInvoiceChange = (e, key) => {
    const invoice = data.neoprod_breakdown.invoice.find((i) => i.key === key);
    const newInvoice = { ...invoice, [e.target.name]: e.target.value };
    const newInvoices = data.neoprod_breakdown.invoice.map((i) => {
      if (i.key === key) return newInvoice;
      return { ...i };
    });
    setData({
      ...data,
      neoprod_breakdown: { ...data.neoprod_breakdown, invoice: newInvoices },
    });
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "auto",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      zIndex: 9999,
    }),
    base: (provided) => ({
      ...provided,
      height: 10,
    }),
  };

  const subDomainsAllOptions = groupOptions.filter((i) => !i.domain);
  const allDomainSubgroup = subDomainsAllOptions.filter((i) => !i.parent);
  const allDomainsSubgroupGroup = {
    label: genLang({ en: "All domains", fr: "Tout domaines" }),
    options: allDomainSubgroup,
  };
  const subDomainFilteredOptions =
    selectedDomains.map((i) => ({ label: i?.label, options: i?.subgroups })) ??
    [];
  const subDomainFilteredOptionsWithAll = [
    allDomainsSubgroupGroup,
    ...subDomainFilteredOptions,
  ];

  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalHeader toggle={close}>Créer un produit</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="5">
            <Label className="price-label">Titre FR</Label>
            <Input
              value={data?.title?.fr}
              onChange={(e) => handleLabelChange(e, "fr")}
            />
          </Col>

          <Col sm="5">
            <Label className="price-label">Titre EN</Label>
            <Input
              value={data?.title?.en}
              onChange={(e) => handleLabelChange(e, "en")}
            />
          </Col>

          <Col sm="2">
            <Label className="price-label">{`Prix ($)`}</Label>
            <Input
              value={data?.amount}
              onChange={(e) => setData({ ...data, amount: e.target.value })}
            />
          </Col>

          {/* <Col sm="2">
            <Label className="price-label">Class</Label>
            <Input
              value={data?.class}
              type="number"
              onChange={(e) => setData({ ...data, class: e.target.value })}
            />
          </Col> */}
        </Row>

        <Row>
          <Col sm="5">
            <Label className="price-label">Domain</Label>
            <Select
              styles={customStyles}
              options={domains}
              value={domains.find((i) => i.value === data.domain) || ""}
              onChange={(e) => {
                !e
                  ? setData({ ...data, domain: "", domain_name: "" })
                  : setData({ ...data, domain: e.value, domain_name: e.label });
              }}
            />
          </Col>

          <Col sm="5">
            <Label className="price-label">Subdomain</Label>
            <Input
              value={data?.sub_domain}
              onChange={(e) => setData({ ...data, sub_domain: e.target.value })}
            />
          </Col>

          <Col sm="2">
            <Label className="price-label">Province</Label>
            <Select
              styles={customStyles}
              options={provinces}
              value={provinces.find((i) => i.value === data.province) || ""}
              onChange={(e) => {
                !e
                  ? setData({ ...data, province: "" })
                  : setData({ ...data, province: e.value });
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="5">
            <Label className="price-label">Type</Label>
            <Select
              styles={customStyles}
              isMulti
              options={types}
              value={types.filter((i) => data.type.includes(i.value))}
              onChange={(e) => {
                !e
                  ? setData({ ...data, type: [] })
                  : setData({ ...data, type: e.map((i) => i.value) });
              }}
            />
          </Col>

          <Col sm="5">
            <Label className="price-label">Cat reporting</Label>
            <Input
              value={data.cat_reporting}
              onChange={(e) =>
                setData({ ...data, cat_reporting: e.target.value })
              }
            />
          </Col>

          <Col sm="2">
            <Label className="price-label">Cat</Label>
            <Input
              type="number"
              value={data.cat}
              onChange={(e) =>
                setData({ ...data, cat: Number(e.target.value) })
              }
            />
          </Col>
        </Row>

        <Row>
          <Col sm="2">
            <Label className="price-label">Base product</Label>
            <Input
              type="number"
              value={data.base_product}
              onChange={(e) =>
                setData({ ...data, base_product: Number(e.target.value) })
              }
            />
          </Col>

          <Col sm="2">
            <Label className="price-label">{`Frais`}</Label>
            <Input
              type="number"
              value={data?.discountFree}
              onChange={(e) =>
                setData({ ...data, discountFree: Number(e.target.value) })
              }
            />
          </Col>

          <Col sm="2">
            <FormControlLabel
              style={{ marginTop: "1.5rem", fontWeight: "600" }}
              label="Upsell"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={data.upsell === 1}
                  onChange={(e) =>
                    setData({ ...data, upsell: e.target.checked ? 1 : 0 })
                  }
                />
              }
            />
          </Col>

          <Col sm="2">
            <FormControlLabel
              style={{ marginTop: "1.5rem", fontWeight: "600" }}
              label="Apartir"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={data.apartir}
                  onChange={(e) =>
                    setData({ ...data, apartir: e.target.checked })
                  }
                />
              }
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col
            sm="1"
            style={{ fontWeight: "600", marginTop: "1rem", fontSize: "15px" }}
          >
            {genLang({ en: "Groups", fr: "Groupes" })}
          </Col>
          <Col sm="5">
            <Label className="price-label">
              {genLang({ en: "Domains", fr: "Domaines" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={groupDomains}
              value={selectedDomains}
              onChange={(e) => setSelectedDomains(e ?? [])}
            />
          </Col>
          <Col sm="5">
            <Label className="price-label">
              {genLang({ en: "Subgroups", fr: "Sous-groupes" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={subDomainFilteredOptionsWithAll}
              value={selectedSubgroups}
              onChange={(e) => setSelectedSubgroups(e ?? [])}
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col
            sm="2"
            style={{ fontWeight: "600", marginTop: "1rem", fontSize: "15px" }}
          >
            {genLang({ en: "Linked products", fr: "Produits liés" })}
          </Col>
          <Col>
            <Label className="price-label">
              {genLang({ en: "Linked products", fr: "Produits liés" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={productOptions}
              value={
                productOptions?.filter((i) =>
                  data.linked_products.includes(i.value)
                ) ?? ""
              }
              onChange={(e) =>
                setData({
                  ...data,
                  linked_products: e?.map((i) => i.value) ?? [],
                })
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "0.5rem" }}>
          <Col>
            <span
              style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <h6 style={{ marginBottom: "0px" }}>Neoprod Breakdown</h6>
            </span>
          </Col>
        </Row>
        <Row>
          {data.neoprod_breakdown.invoice.map((i) => {
            return (
              <Col sm="3" key={i.key}>
                <Label className="price-label">{i.fr}</Label>
                <Input
                  name={"percentage"}
                  value={i?.percentage}
                  onChange={(e) => handleInvoiceChange(e, i?.key)}
                  type="number"
                />
              </Col>
            );
          })}
        </Row>

        <Row>
          <Button
            block
            color="primary"
            style={{
              borderRadius: "50px",
              marginTop: "1rem",
              height: "35px",
              margin: "20px 10px 0px 10px",
            }}
            onClick={async () => {
              const domainValues = selectedDomains.map((i) => i.value);
              const subgroupValues = selectedSubgroups.map((i) => i.value);
              const groups = [...domainValues, ...subgroupValues];
              const infos = { ...data, groups };
              infos.neoprod_breakdown.fideicommis =
                data?.neoprod_breakdown?.invoice?.find((i) => i?.key === "2")
                  ?.percentage ?? 0;
              handleCreateProductSubmit(infos);
              setData(defaultData);
            }}
          >
            {loading ? <Spinner size="sm" /> : "Enregister"}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

function EditProductModal({
  isOpen,
  close,
  selectedProduct,
  setSelectedProduct,
  changeSelectedProductTitle,
  changeSelectedProductPrice,
  changeSelectedProductFee,
  changeSelectedProductGroups,
  handleSubmit,
  loading,
  handleInvoiceChange,
  groupOptions,
  groupDomains,
  handleChangeGroups,
  handleLinkedProductsChange,
  productOptions,
}) {
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [selectedSubgroups, setSelectedSubgroups] = useState([]);

  useEffect(() => {
    const preSelectedDomains = groupDomains.filter((i) =>
      selectedProduct?.groups?.includes(i.value)
    );
    const preSelectedSubgroups = subDomainsAllOptions.filter((i) =>
      selectedProduct?.groups?.includes(i.value)
    );
    setSelectedDomains(preSelectedDomains);
    setSelectedSubgroups(preSelectedSubgroups);
  }, [selectedProduct]);

  const handleSubgroupChange = (subgroups) => {
    setSelectedSubgroups(subgroups);
    handleChangeGroups([...subgroups, ...selectedDomains]);
  };
  const handleDomainChange = (domains) => {
    setSelectedDomains(domains);
    handleChangeGroups([...domains, ...selectedSubgroups]);
  };

  const subDomainsAllOptions = groupOptions.filter((i) => !i.domain);
  const allDomainSubgroup = subDomainsAllOptions.filter((i) => !i.parent);
  const allDomainsSubgroupGroup = {
    label: genLang({ en: "All domains", fr: "Tout domaines" }),
    options: allDomainSubgroup,
  };
  const subDomainFilteredOptions =
    selectedDomains.map((i) => ({ label: i?.label, options: i?.subgroups })) ??
    [];
  const subDomainFilteredOptionsWithAll = [
    allDomainsSubgroupGroup,
    ...subDomainFilteredOptions,
  ];

  const provinces = [
    { label: "QC", value: "QC" },
    { label: "ON", value: "ON" },
  ];

  const options =
    selectedProduct?.options?.map((i, index) => {
      return { ...i, index };
    }) || [];

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "auto",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto",
      zIndex: 9999,
    }),
    base: (provided) => ({
      ...provided,
      height: 10,
    }),
  };
  return (
    <Modal isOpen={isOpen} centered size="lg">
      <ModalHeader toggle={close}>Modifier Produit</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="5">
            <Label className="price-label">Titre FR</Label>
            <Input
              value={selectedProduct?.title?.fr}
              onChange={(e) => changeSelectedProductTitle(e, "fr")}
            />
          </Col>

          <Col sm="5">
            <Label className="price-label">Titre EN</Label>
            <Input
              value={selectedProduct?.title?.en}
              onChange={(e) => changeSelectedProductTitle(e, "en")}
            />
          </Col>

          <Col sm="2">
            <Label className="price-label">{`Prix ($)`}</Label>
            <Input
              value={selectedProduct?.amount}
              onChange={(e) => changeSelectedProductPrice(e)}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="2">
            <Label className="price-label">Province</Label>
            <Select
              styles={customStyles}
              options={provinces}
              value={
                provinces.find((i) => i.value === selectedProduct?.province) ||
                ""
              }
              onChange={(e) => {
                !e
                  ? setSelectedProduct({ ...selectedProduct, province: "" })
                  : setSelectedProduct({
                      ...selectedProduct,
                      province: e.value,
                    });
              }}
            />
          </Col>
          <Col sm="2">
            <Label className="price-label">{`Frais`}</Label>
            <Input
              value={selectedProduct?.discountFree}
              onChange={(e) => changeSelectedProductFee(e)}
            />
          </Col>

          <Col sm="2">
            <FormControlLabel
              style={{ marginTop: "1.5rem", fontWeight: "600" }}
              label="Upsell"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={selectedProduct?.upsell === 1}
                  onChange={(e) =>
                    setSelectedProduct({
                      ...selectedProduct,
                      upsell: e.target.checked ? 1 : 0,
                    })
                  }
                />
              }
            />
          </Col>
        </Row>

        <Row style={{ alignItems: "center" }}>
          <Col
            sm="1"
            style={{ fontWeight: "600", marginTop: "1rem", fontSize: "15px" }}
          >
            {genLang({ en: "Groups", fr: "Groupes" })}
          </Col>
          <Col sm="5">
            <Label className="price-label">
              {genLang({ en: "Domains", fr: "Domaines" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={groupDomains}
              value={selectedDomains}
              onChange={(e) => handleDomainChange(e ?? [])}
            />
          </Col>
          <Col sm="5">
            <Label className="price-label">
              {genLang({ en: "Subgroups", fr: "Sous-groupes" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={subDomainFilteredOptionsWithAll}
              value={selectedSubgroups}
              onChange={(e) => handleSubgroupChange(e ?? [])}
            />
          </Col>
        </Row>
        <Row style={{ alignItems: "center" }}>
          <Col
            sm="2"
            style={{ fontWeight: "600", marginTop: "1rem", fontSize: "15px" }}
          >
            {genLang({ en: "Linked products", fr: "Produits liés" })}
          </Col>
          <Col>
            <Label className="price-label">
              {genLang({ en: "Linked products", fr: "Produits liés" })}
            </Label>
            <Select
              menuPlacement={"auto"}
              styles={customStyles}
              isMulti
              options={productOptions}
              value={
                productOptions?.filter((i) =>
                  selectedProduct?.linked_products?.includes(i.value)
                ) ?? ""
              }
              onChange={(e) => handleLinkedProductsChange(e ?? [])}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: "0.5rem" }}>
          <Col>
            <span
              style={{ display: "flex", alignItems: "center", gap: "1rem" }}
            >
              <h6 style={{ marginBottom: "0px" }}>Neoprod Breakdown</h6>
            </span>
          </Col>
        </Row>
        <Row>
          {selectedProduct?.neoprod_breakdown?.invoice?.map((i) => {
            return (
              <Col sm="3" key={i.key}>
                <Label className="price-label">{i.fr}</Label>
                <Input
                  name={"percentage"}
                  value={i?.percentage}
                  onChange={(e) => handleInvoiceChange(e, i?.key)}
                  type="number"
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Button
            block
            color="primary"
            style={{
              borderRadius: "50px",
              marginTop: "1rem",
              height: "35px",
              margin: "20px 10px 0px 10px",
            }}
            onClick={handleSubmit}
          >
            {loading ? <Spinner size="sm" /> : "Enregister"}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
}

const DownloadButtonCSV = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
    const csvArray = objArray.map((i) => {
      const { id, amount, title, neoprod_breakdown, province, upsell } = i;
      const service_juridique =
        neoprod_breakdown?.invoice.find((i) => i.key === "3")?.percentage ??
        "-";
      const gestion_du_dossier =
        neoprod_breakdown?.invoice.find((i) => i.key === "4")?.percentage ??
        "-";
      const utilisation_technologique =
        neoprod_breakdown?.invoice.find((i) => i.key === "5")?.percentage ??
        "-";
      const fidéicommis =
        neoprod_breakdown?.invoice.find((i) => i.key === "2")?.percentage ??
        "-";
      const obj = {
        id,
        amount,
        title_fr: title.fr,
        title_en: title.en,
        province,
        upsell: upsell ? "Upsell" : "",
        service_juridique: String(service_juridique),
        gestion_du_dossier: String(gestion_du_dossier),
        utilisation_technologique: String(utilisation_technologique),
        fidéicommis: String(fidéicommis),
      };
      return obj;
    });

    let str =
      "id,amount,title_fr,title_en,province,upsell,service_juridique,gestion_du_dossier,utilisation_technologique,fidéicommis\r\n";

    csvArray.forEach((row) => {
      str +=
        `${row.id},${row.amount},"${row.title_fr.replace(
          /"/g,
          '""'
        )}","${row.title_en.replace(/"/g, '""')}",${row.province || ""},"${
          row.upsell
        }",` +
        `${row.service_juridique},${row.gestion_du_dossier},${row.utilisation_technologique},${row.fidéicommis}\r\n`;
    });

    const encodedCSV = new TextEncoder().encode(str);

    return new TextDecoder("utf-8").decode(encodedCSV);
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(data);

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = (window.URL || window.webkitURL).createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName || "products.csv";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    (window.URL || window.webkitURL).revokeObjectURL(url);
  };

  return (
    <Button
      size="sm"
      color="primary"
      outline
      style={{ fontWeight: "600", borderRadius: "50px" }}
      onClick={downloadCSV}
    >
      <FontAwesomeIcon icon={faDownload} /> Télécharger CSV
    </Button>
  );
};
